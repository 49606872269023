import {isVoid} from "@/libs/utils";

export default {
  validateDate: (rule, value, callback) => {
    if (!value) {
      callback(new Error('请选择下单日期'));
    } else {
      callback();
    }
  },
  validateDepartment: (rule, value, callback) => {
    if (!value.id) {
      callback(new Error('请选择部门'));
    } else {
      callback();
    }
  },
  validateStaff: (rule, value, callback) => {
    if (!value.id) {
      callback(new Error('请选择跟单员'));
    } else {
      callback();
    }
  },
  validateProduct: (rule, value, callback) => {
    if (!value.id) {
      callback(new Error('请选择产品'));
    } else {
      callback();
    }
  },
  validateColor: (rule, value, callback) => {
    if (!value.id) {
      callback(new Error('请选择色号'));
    } else {
      callback();
    }
  },
  validateNumber: (rule, value, callback) => {
    if (isVoid(value)) {
      callback(new Error('请填写缸数'));
    } else if (value < 1) {
      callback(new Error('缸数不能小于1'));
    } else {
      callback();
    }
  }
}
