<template>
  <div class="production-order main-panel large" v-loading="isPageLoading">
    <h3 class="panel-title" v-if="!layerInfo?.id">染色生产单</h3>
    <el-button type="danger"
               size="mini"
               round
               class="save"
               :class="{'update-btn': layerInfo?.id}"
               :loading="isLoading"
               @click="save">保存
    </el-button>
    <el-form :model="form" :rules="formRules" ref="productionForm" label-width="85px" inline @submit.prevent>
      <el-form-item label="下单日期" prop="date">
        <el-date-picker v-model="form.date" type="date" :size="controlSize" placeholder="下单日期" :clearable="false" style="width: 150px;" />
      </el-form-item>
      <el-form-item label="工厂名" prop="department">
        <el-select :size="controlSize" filterable v-model="form.department" placeholder="请选择工厂" value-key="id" style="width: 150px;">
          <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="跟单员" prop="staff">
        <el-select v-if="!$store.state.isGd" :size="controlSize" filterable v-model="form.staff" placeholder="请选择跟单员" value-key="id" style="width: 150px;">
          <el-option v-for="item in staffs" :key="item.id" :label="item.name" :value="item" />
        </el-select>
        <span v-else>{{ form.staff.name }}</span>
      </el-form-item>
      <el-form-item label="下单属性" prop="type">
        <el-radio-group v-model="form.type" :size="controlSize">
          <el-radio-button :label="1">日常</el-radio-button>
          <el-radio-button :label="2">订单</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-form :model="addForm" :rules="addFormRules" ref="addForm" label-width="58px" inline @submit.prevent>
      <el-form-item label="品名" prop="product">
        <el-select :size="controlSize" filterable v-model="addForm.product" placeholder="请选择产品" value-key="id">
          <el-option v-for="item in products" :key="item.id" :label="item.pname" :value="item">
            <span style="float: left">{{ item.pname }}</span>
            <span style="float: right; color: #8492a6; font-size: 12px">{{ item.fabricName }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="色号" prop="color">
        <el-select :size="controlSize" filterable v-model="addForm.color" placeholder="请选择色号" value-key="id" style="width: 150px;">
          <el-option v-for="item in colors" :key="item.id" :label="item.colornumber" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="缸数" prop="number">
        <el-input v-model.number.lazy="addForm.number"
                  :size="controlSize"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  placeholder="缸数"
                  style="width: 70px;" />
      </el-form-item>
      <el-form-item label="备注" prop="content">
        <el-input v-model="addForm.content"
                  :size="controlSize"
                  placeholder="备注"
                  style="width: 170px;" />
      </el-form-item>
      <el-form-item>
        <el-button type="danger" :size="controlSize" @click="addRow" style="margin-left: 10px;">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" height="500">
      <el-table-column align="center" prop="product.pname" label="品名"></el-table-column>
      <el-table-column align="center" prop="product.fabricName" label="胚布名"></el-table-column>
      <el-table-column align="center" prop="color.colornumber" label="色号"></el-table-column>
      <el-table-column align="center" prop="number" label="缸数"></el-table-column>
      <el-table-column align="center" prop="content" label="备注"></el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <div class="options">
            <i class="update-icon el-icon-edit" title="修改" @click="openLayer({...scope.row, itemIndex: scope.$index})"></i>
            <el-popconfirm title="确认删除吗?" confirmButtonText='确认' cancelButtonText='取消' @confirm="delItem(scope.$index)">
              <template #reference>
                <i class="del-icon el-icon-close" title="删除"></i>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <my-layer v-if="Object.keys(updateInfo).length"
              :title="updateInfo.layerTitle"
              :component="updateComponent"
              @handle="updateItem"
              :layer-info="updateInfo"
              @close="updateInfo = {}" />
  </div>
</template>

<script>
import _ from "lodash";
import validatorRules from "@/views/production/production-order/validatorRules";
import {shallowRef} from "vue";
import MyLayer from "@/components/MyLayer/MyLayer";

export default {
  components: {MyLayer},
  props: {
    layerInfo: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      controlSize: "mini",
      form: {
        date: null,
        department: {},
        staff: {},
        type: null
      },
      list: [],

      formRules: {
        date: [{required: true, validator: validatorRules.validateDate, trigger: "change"}],
        department: [{required: true, validator: validatorRules.validateDepartment, trigger: "change"}],
        staff: [{required: true, validator: validatorRules.validateStaff, trigger: "change"}],
        type: [{required: true, message: "请选择下单属性", trigger: "change"}],
      },
      addForm: {
        product: {},
        color: {},
        number: null,
        content: ""
      },
      addFormRules: {
        product: [{required: true, validator: validatorRules.validateProduct, trigger: "change"}],
        color: [{required: true, validator: validatorRules.validateColor, trigger: "change"}],
        number: [{required: true, validator: validatorRules.validateNumber, trigger: "blur"}],
      },

      departments: [],
      staffs: [],
      products: [],

      isLoading: false,
      isPageLoading: false,

      updateComponent: undefined,
      updateInfo: {}
    }
  },

  computed: {
    colors() {
      if (!this.addForm.product.id) return [];
      return this.addForm.product.colorcards;
    }
  },

  watch: {
    "addForm.product": {
      handler: async function () {
        this.addForm.color = {}
        await this.$nextTick()
        this.$refs["addForm"].clearValidate("color")
      },
      deep: true
    }
  },

  async mounted() {
    this.isPageLoading = true
    this.departments = await this.$getList.getDepartments();
    this.staffs = await this.$getList.getStaffs();
    this.products = await this.$getList.getProducts();
    this.init()
    this.isPageLoading = false
  },

  methods: {
    init() {
      this.form.date = new Date()
      this.list = []
      if (this.$store.state.isGd) {
        this.form.staff = this.staffs.find(staff => staff.id === this.$store.state.userinfo.id)
        this.form.department = this.departments.find(department => department.id === this.$store.state.departmentId)
      }
    },

    addRow() {
      this.$refs["addForm"].validate(async valid => {
        if (valid) {
          this.list.unshift(_.cloneDeep(this.addForm))
          this.$refs["addForm"].resetFields()
        }
      })
    },

    async openLayer(info) {
      this.updateComponent = shallowRef((await import("./update/update")).default)
      this.updateInfo = {...info, layerTitle: "修改"}
    },

    updateItem(info) {
      this.list.splice(info.itemIndex, 1, info);
    },

    delItem(index) {
      this.list.splice(index, 1);
    },

    async save() {
      this.$refs["productionForm"].validate(async valid => {
        if (valid) {
          if (!this.list.length) {
            this.$message.warning("请新增数据");
            return false;
          }
          const hasProcessRes = await this.$api.Base.Process.getList().catch(() => {})
          if (hasProcessRes?.code === 200 && !hasProcessRes.data.length) {
            this.$message.warning("请先设置流程")
            return false;
          }

          this.isLoading = true
          const sendData = {
            production: {
              date: this.$day(this.form.date).format("YYYY-MM-DD"),
              departmentId: this.form.department.id,
              userId: this.form.staff.id,
              type: this.form.type
            },
            productionInfos: this.list.map(listItem => ({
              productId: listItem.product.id,
              colorcardId: listItem.color.id,
              cylindersNum: listItem.number,
              content: listItem.content
            })),
          }

          const res = await this.$api.Production.ProductionOrder.add(sendData).catch(() => {})
          this.isLoading = false
          if (res?.code === 200) {
            this.$message.success("新增成功")
            Object.assign(this.form, this.$options.data().form)
            this.$refs["productionForm"].resetFields()
            this.$refs["addForm"].resetFields()
            this.init()
          } else {
            this.$message.error(res?.msg || "新增失败")
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "production-order";
</style>
